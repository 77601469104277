var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-orderBcmTable"
  }, [_c('v-card-title', [_c('DataTableFilter', {
    staticClass: "col-lg-6 pl-0",
    attrs: {
      "width-of-filters-titles": "2",
      "filters": _vm.filterObject
    },
    on: {
      "update": _vm.updateFilters
    }
  }), _c('v-spacer'), _c('v-text-field', {
    staticClass: "text-field-search",
    attrs: {
      "append-icon": "fas fa-search",
      "label": _vm.$t('Search')
    },
    model: {
      value: _vm.formSearch,
      callback: function callback($$v) {
        _vm.formSearch = $$v;
      },
      expression: "formSearch"
    }
  })], 1), _c('v-data-table', {
    staticClass: "bcmPartTable",
    attrs: {
      "loading": _vm.loadingTableBCM,
      "headers": _vm.headers,
      "items": _vm.order,
      "search": _vm.formSearch,
      "custom-filter": _vm.customFilter,
      "sort-by": "created",
      "sort-desc": true,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    on: {
      "click:row": function clickRow($event) {
        return _vm.goToPageItem($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.status",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, [item.status === 'SCHEDULED' ? [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-clock ")])] : item.status === 'MANUFACTURING' ? [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "primary"
          }
        }, [_vm._v(" fas fa-spinner ")])] : item.status === 'MANUFACTURED' ? [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "primary"
          }
        }, [_vm._v(" fas fa-spinner ")])] : item.status === 'DELIVERY' ? [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "primary"
          }
        }, [_vm._v(" fas fa-spinner ")])] : item.status === 'DELIVERED' ? [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "success"
          }
        }, [_vm._v(" fas fa-check ")])] : _vm._e(), _vm._v(" " + _vm._s(_vm.$t(item.status)) + " ")], 2)];
      }
    }, {
      key: "item.invoiceNumber",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.invoiceNumber) + " ")])];
      }
    }, {
      key: "item.quantity",
      fn: function fn(_ref3) {
        var _item$quote$dispatch$;

        var item = _ref3.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, [_vm._v(" " + _vm._s((_item$quote$dispatch$ = item.quote.dispatch.items.find(function (i) {
          return i.part._id === _vm.part._id;
        })) === null || _item$quote$dispatch$ === void 0 ? void 0 : _item$quote$dispatch$.quantity) + " ")])];
      }
    }, {
      key: "item.technology",
      fn: function fn(_ref4) {
        var _item$quote$dispatch$2, _item$quote$dispatch$3;

        var item = _ref4.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, [_c('v-chip', {
          staticClass: "ma-1"
        }, [_vm._v(" " + _vm._s((_item$quote$dispatch$2 = item.quote.dispatch.items.find(function (i) {
          return i.part._id === _vm.part._id;
        })) === null || _item$quote$dispatch$2 === void 0 ? void 0 : (_item$quote$dispatch$3 = _item$quote$dispatch$2.manufacturingInformation) === null || _item$quote$dispatch$3 === void 0 ? void 0 : _item$quote$dispatch$3.dnaTechnology) + " ")])], 1)];
      }
    }, {
      key: "item.material",
      fn: function fn(_ref5) {
        var _item$quote$dispatch$4, _item$quote$dispatch$5;

        var item = _ref5.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, [_c('v-chip', {
          staticClass: "ma-1"
        }, [_vm._v(" " + _vm._s(_vm.$t((_item$quote$dispatch$4 = item.quote.dispatch.items.find(function (i) {
          return i.part._id === _vm.part._id;
        })) === null || _item$quote$dispatch$4 === void 0 ? void 0 : (_item$quote$dispatch$5 = _item$quote$dispatch$4.manufacturingInformation) === null || _item$quote$dispatch$5 === void 0 ? void 0 : _item$quote$dispatch$5.dnaMaterial)) + " ")])], 1)];
      }
    }, {
      key: "item.created",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "long", _vm.$userLocale)) + " ")])];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('v-btn', {
          attrs: {
            "color": "info",
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-eye ")])], 1)];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }