var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('OrderBcmTable', {
    attrs: {
      "order": _vm.orderBCM,
      "part": _vm.part,
      "loading-table-b-c-m": _vm.loading
    },
    on: {
      "goToPageItem": function goToPageItem($event) {
        return _vm.goToPageItem($event);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }