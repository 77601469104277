<template lang="html" src="./orderBcmTable.template.vue"></template>

<style lang="scss" src="./orderBcmTable.scss"></style>

<script>
const i18nData = require('./orderBcmTable.i18n');
import { DataTableFilter, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

export default {
  name: 'OrderBcmTable',
  components: { DataTableFilter },
  props: {
    order: {
      type: Array,
      required: true,
    },
    part: {
      type: Object,
      required: true,
    },
    loadingTableBCM: {
      type: Boolean,
      default: false,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      headers: [],
      formSearch: null,
      filterObject: {
        status: [
          {
            name: this.$t('SCHEDULED'),
            value: 'SCHEDULED',
            icon: 'fas fa-clock',
            iconColor: 'info',
            get selected() {
              return this.number > 0;
            },
            number: 0,
          },
          {
            name: this.$t('DELIVERY'),
            value: 'DELIVERY',
            icon: 'fas fa-spinner',
            iconColor: 'primary',
            get selected() {
              return this.number > 0;
            },
            number: 0,
          },
          {
            name: this.$t('DELIVERED'),
            value: 'DELIVERED',
            icon: 'fas fa-check',
            iconColor: 'success',
            number: 0,
          },
        ]
      },
      statusFilter: [],
    };
  },
  created() {
    this.headers = [
      {
        text: this.$t('Status'),
        value: 'status',
        filter: (value, search, item) => {
          if (this.statusFilter.length === 0 ) return true;
          if (this.statusFilter.includes(value)) return true;
          if (this.statusFilter.includes('DELIVERY') && ['DELIVERY','MANUFACTURING','MANUFACTURED'].includes(value)) return true;
        }
      },
      {
        text: this.$t('PurchaseOrder'),
        value: 'invoiceNumber',
        align: 'center',
      },
      {
        text: this.$t('Quantity'),
        value: 'quantity',
        align: 'center',
      },
      {
        text: this.$t('Technology'),
        value: 'technology',
        align: 'center',
      },
      {
        text: this.$t('Material'),
        value: 'material',
        align: 'center',
      },
      { text: this.$t('Created'), value: 'created', align: 'center' },
      {
        text: this.$t('Action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
  },
  watch: {
    order() {
      this.filterObject.status.forEach(status => {
        status.number = 0;
      });
      this.order.forEach(order => {
        this.filterObject.status.forEach(status => {
          if (
            order.status === 'SCHEDULED' && status.value === 'SCHEDULED' || 
            ['DELIVERY','MANUFACTURING','MANUFACTURED'].includes(order.status) && status.value === 'DELIVERY' ||
            order.status === 'DELIVERED' && status.value === 'DELIVERED'
          ) {
            status.number++;
          }
        });
      });
    }
  },
  methods: {
    goToPageItem(item) {
      this.$router.push({name: 'Order', params: {supplierUUID: `${this.$route.params.supplierUUID}`, orderUUID: `${item._id}`}});
    },
    customFilter(value, search, item) {
      if (!search) return true;
      if (item.quickbooksSupplierInvoiceId.includes(search)) return true;
      if (item[this.part._id].quantity.toString().includes(search)) return true;
      if (item[this.part._id].part.dna.technology.toLowerCase().includes(search.toLowerCase())) return true;
      if (item[this.part._id].part.dna.material.toLowerCase().replaceAll('_',' ').includes(search.toLowerCase())) return true;
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    updateFilters(updatedFilters) {
      this.statusFilter = updatedFilters.status;
    },
  },
};
</script>
