<template>
  <OrderBcmTable
    :order="orderBCM"
    :part="part"
    :loading-table-b-c-m="loading"
    @goToPageItem="goToPageItem($event)"
  ></OrderBcmTable>
</template>

<script>
import {
  EventBus,
  ApiErrorParser,
} from '@cloudmanufacturingtechnologies/portal-components';

import OrderBcmTable from '../../../components/orderBcmTable/OrderBcmTable';

const i18nData = require('./pageBcmPartOrder.i18n.json');

export default {
  name: 'PageBcmPartOrder',
  components: {
    OrderBcmTable,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      part: {},
      order: null,
      orderBCM: [],
      loading: false,
    };
  },
  created() {

    this.getSupplierPart();
    EventBus.$on('saveBarSubmit', this.saveNestingSettings);
    EventBus.$on('saveBarCancel', this.cancelForms);
  },
  beforeDestroy() {
    EventBus.$off('saveBarSubmit', this.saveNestingSettings);
    EventBus.$off('saveBarCancel', this.cancelForms);
  },
  methods: {
    /**
     * GET SUPPLIER PART
     */
    getSupplierPart() {
      this.$apiInstance
        .getSupplierPart(
          this.$route.params.supplierUUID,
          this.$route.params.partUUID
        )
        .then(
          (partData) => {
            this.part = partData;
            this.getSupplierOrders();
          },
          /**
           * ERROR GET SUPPLIER PART
           */
          (error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    
    getSupplierOrders() {
      this.loading = true;
      /**
       * GET SUPPLIER ORDERS
       */
      this.$apiInstance
        .getSupplierOrders(this.$route.params.supplierUUID, {partUUID: this.$route.params.partUUID}).then(data => {
          this.orderBCM = data;
          this.loading = false;
        });


      // this.$apiInstance
      //   .getSupplierOrders(this.$route.params.supplierUUID)
      //   .then((data) => {
      //     data.forEach((order) => {
      //       this.$apiInstance
      //         .getSupplierOrder(this.$route.params.supplierUUID, order._id)
      //         .then(
      //           (supplierOrderData) => {
      //             this.order = supplierOrderData;
      //             this.order.items.forEach((item) => {
      //               if (item.part._id.includes(this.$route.params.partUUID)) {
      //                 this.order[item.part._id] = item;
      //                 this.orderBCM.push(this.order);
      //               }
      //             });
      //             this.loading = false;
      //           },
      //           (error) => {
      //             /**
      //              * ERROR GET ORDER
      //              */
      //             ApiErrorParser.parse(error);
      //           }
      //         );
      //     });
      //   });

    },
    goToPageItem(itemId) {
      this.$router.push({name: 'Order', params: {supplierUUID: `${this.$route.params.supplierUUID}`, orderUUID: `${itemId}`}});
    },
  },
};
</script>
